<template>
  <div>
    <div class="buttons">
      <el-button
        @click="handler_showFollowCustomer()"
        v-bind:class="computeCustomerActiveBtn"
        >{{ customerActiveBtnValue }}</el-button
      >

      <el-button
        @click="handler_showUncompletePm()"
        v-bind:class="computeUncompletePmActiveBtn"
        >{{ uncompletePmBtnValue }}</el-button
      >
    </div>
    <div v-if="showFollowCustomer">
      <el-table
        :data="followCustomerPage.list"
        v-loading="showFollowCustomerLoading"
        element-loading-text="拼命加载中"
        border
        width="width:100%; height:50%"
      >
        <el-table-column
          prop="customerName"
          label="客户名称"
          width="250"
        ></el-table-column>
        <el-table-column prop="customerTag" label="客户标签">
          <template  #default="scope">
            <el-tag style="margin-left: 5px;"
                    v-for="(tag, index) in customerTagInfo[scope.row.customerId]"
                    :key="tag.name">
              {{tag.name}}
            </el-tag>
          </template>
        </el-table-column>
        <el-table-column
          prop="followProjectType"
          label="跟进类型"
          width="120"
          :formatter="formatterFollowProjectType"
        ></el-table-column>
        <el-table-column
          prop="followProjectStarttime"
          label="开始跟进时间"
          width="120"
        ></el-table-column>
        <el-table-column label="操作" conter width="220">
          <template #default="scope">
            <el-button
              type="text"
              icon="el-icon-edit"
              @click="handler_followCustomer(scope.row)"
              >跟进
            </el-button>
<!--            <el-button type="text" icon="el-icon-edit" @click="handle_follow(scope.row)">跟进</el-button>-->
            <el-button type="text" icon="el-icon-view" @click="handle_view(scope.row)">跟进记录</el-button>
            <el-button type="text" icon="el-icon-edit" @click="handle_edit(scope.row)">编辑</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        @current-change="devlogChangeForFollowCustomer"
        :current-page="followCustomerPage.pageNum"
        :page-size="followCustomerPage.pageSize"
        layout="total, prev, pager, next, jumper"
        :total="followCustomerPage.total"
      >
      </el-pagination>
      
      <FollowRecordDig :listDigTitle="recordListTitle" :visible.sync="recordListShow" v-if="recordListShow" :customerInfo="customerInfo" :listDigShow="recordListShow"></FollowRecordDig>
    </div>
    <div v-if="showUncompletePm">
      <el-row
        v-for="(plan, index1) in uncompletePmPage.list"
        class="plan-container"
        :key="plan.id"
      >
        <el-row class="plan-title">
          <el-col :span="12" class="fl">{{ plan.name }}</el-col>
          <el-col :span="12">
            <span class="fr">{{ computeDateYMD(index1) }}</span>
          </el-col>
        </el-row>
        <el-row class="plan-body">
          <el-col
            :span="6"
            v-for="project in plan.projects"
            class="project-container"
            :key="project.id"
          >
            <el-row>
              <el-col :span="6" class="project-left"
                ><i class="el-icon-lx-home"></i
              ></el-col>
              <el-col :span="18" class="project-right">
                <div class="project-name">
                  {{ project.name }}
                </div>
                <div class="project-bottom">
                  <span
                    class="project-link fr"
                    @click="handler_completeproject(project.id)"
                    >完善材料</span
                  >
                </div>
              </el-col>
            </el-row>
          </el-col>
        </el-row>
      </el-row>
      <el-pagination
        @current-change="devlogChangeForUncompletePm"
        :current-page="uncompletePmPage.pageNum"
        :page-size="uncompletePmPage.pageSize"
        layout="total, prev, pager, next, jumper"
        :total="uncompletePmPage.total"
      >
      </el-pagination>

    </div>
  </div>
</template>

<script>
import CUSTOMER from "../api/biz/api_customer";
import PM from "../api/base/api_pm";
import syscomm from "../utils/syscomm";
import COMM from "../api/base/api_comm";
import FollowRecordDig from "../views/follow/recordListDialog";

export default {
  name: "infollowlist", // 跟进计划列表
  components: {
    FollowRecordDig
  },
  data() {
    return {
      showFollowCustomer: true,
      showUncompletePm: false,
      showFollowCustomerLoading: false,
      showUncompletePmLoading: false,
      followCustomerPage: { list: [], pageNum: 1, pageSize: 10, total: 0 },
      uncompletePmPage: { list: [], pageNum: 1, pageSize: 10, total: 0 },
      totalFollowCustomer: null,
      totalUncompletePm: null,
      customerActiveBtnValue: "待跟进客户",
      uncompletePmBtnValue: "待完善项目",
      recordListShow: false,
      recordListTitle: '客户跟进记录',
      customerInfo: {},
      customerTagList: [],
      customerTagInfo: {}
    };
  },
  // 初始化加载
  mounted() {
    this.init();
  },
  computed: {
    computeCustomerActiveBtn() {
      return this.showFollowCustomer
        ? {
            "btn-active": true,
            "el-button ": false,
            "el-button--default": false,
          }
        : {
            "btn-active": false,
            "el-button ": true,
            "el-button--default": true,
          };
    },
    computeUncompletePmActiveBtn() {
      return this.showUncompletePm
        ? {
            "btn-active": true,
            "el-button": false,
            "el-button--default": false,
          }
        : {
            "btn-active": false,
            "el-button": true,
            "el-button--default": true,
          };
    },
    computeDateYMD() {
      return function (index) {
        if (this.uncompletePmPage && this.uncompletePmPage.list) {
          var plan = this.uncompletePmPage.list[index];
          if (plan && plan.addtime) {
            var str = plan.addtime;
            var date = new Date(str);
            if (date) {
              var Y = date.getFullYear() + "/";
              var M =
                (date.getMonth() + 1 < 10
                  ? "0" + (date.getMonth() + 1)
                  : date.getMonth() + 1) + "/";
              var D = date.getDate();
              var df = Y + M + D;
              return df;
            }
          }
        }
        return "";
      };
    },
  },
  // 方法定义
  methods: {
    init() {
      this.init_base();
      this.initPageData();
    },
    // 加载初始数据支持
    init_base(){
      COMM.getObjType({type: 'customertag'}).then((res) => {
        this.customerTagList = res;
      });
    },

    // 加载页面数据
    initPageData() {
      if (this.showUncompletePm || !this.totalUncompletePm) {
        this.showUncompletePmLoading = true;
        PM.listUncompleteProject({
          pageNum: this.uncompletePmPage.pageNum,
          pageSize: this.uncompletePmPage.pageSize,
        }).then((res) => {

          this.showUncompletePmLoading = false;
          if (200 == res.code) {
            var data = res.pageData;
            this.uncompletePmPage.total = data.total;
            this.uncompletePmPage.list = data.list;

            PM.countListUncompleteProject({}).then((countRes) => {
              if (200 === countRes.code) {
                this.totalUncompletePm = countRes.pageData;
                this.uncompletePmBtnValue =
                  "待完善项目(" + this.totalUncompletePm + ")";
              }
            });
          } else {
            this.$message.error(res.msg);
            this.uncompletePmPage.list = [];
            this.uncompletePmBtnValue = "待完善项目";
          }
        });
      }
      if (this.showFollowCustomer || !this.totalFollowCustomer) {
        this.showFollowCustomerLoading = true;
        CUSTOMER.listInFollowCustomer({
          pageNum: this.followCustomerPage.pageNum,
          pageSize: this.followCustomerPage.pageSize,
        }).then((res) => {
          this.showFollowCustomerLoading = false;
          if (200 == res.code) {
            var data = res.pageData;
            this.followCustomerPage.total = data.total;
            this.followCustomerPage.list = data.list;
            this.totalFollowCustomer = data.total;
            this.customerActiveBtnValue =
              "待跟进客户(" + this.totalFollowCustomer + ")";

            // 构造客户标签
            this.buildCustomerTags(data.list);
          } else {
            this.$message.error(res.msg);
            this.followCustomerPage.list = [];
            this.customerBtnValue = "待跟进客户";
          }
        });
      }
    },
    buildCustomerTags(customerList) {
      if (customerList == null || customerList.length == 0) {
        return;
      }

      console.log(customerList);
      for (const index in customerList) {
        let customer = customerList[index];
        let customerId = customer['customerId'];

        let customerCheckedTags = [];
        let tag = customer.customerTag||'';
        for (const index in this.customerTagList) {
          let customerTag = this.customerTagList[index];
          if (tag.indexOf(customerTag['value'] + ',') >= 0) {
            customerCheckedTags.push(customerTag);
          }
        }

        this.customerTagInfo[customerId] = customerCheckedTags;
      }
    },
    devlogChangeForFollowCustomer(pageNum) {
      this.followCustomerPage.pageNum = pageNum;
      this.initPageData();
    },
    devlogChangeForUncompletePm(pageNum) {
      this.uncompletePmPage.pageNum = pageNum;
      this.initPageData();
    },
    handler_showFollowCustomer() {
      this.showFollowCustomer = true;
      this.showUncompletePm = false;
      this.initPageData();
    },
    handler_showUncompletePm() {
      this.showUncompletePm = true;
      this.showFollowCustomer = false;
      this.initPageData();
    },

    formatterFollowProjectType(row, coloumn) {
      if (!row.followProjectType) {
        return "新客开拓";
      }
      return row.followProjectType === 1 ? "开拓中" : "未知类型";
    },
    handler_completeproject(id) {
      this.$router.push({
        path: "projectEdit",
        query: {
          projectId: id,
        },
      });
    },
    handler_followCustomer(row) {
      this.$router.push({
        path: "followPlanRecord",
        query: {
          followPlanId: row?.planId,
        },
      });
    },
    // 查看
    handle_view(row) {
      this.customerInfo.id = row.customerId;
      this.recordListShow = true;
    },
    //follow
    handle_follow(row) {
      console.log(row);
      let {...clonerow} = row;
      this.$router.push({ path: 'followRecordAdd', query: { customerId: row.customerId } });

      // path: '../followRecordEdit',
      //     query: {
      //   customerId: row.custid,
      //       followRecordId: row.id
      // }
    },

    handle_edit(row) {
      console.log(row);
      let {...clonerow} = row;
      this.$router.push({path: '/customerEdit/' + row.customerId});
    }
  },
};
</script>

<style scoped>
.fr {
  float: right !important;
}
.fl {
  float: left !important;
}
.buttons {
  margin: 30px 20px;
}
.btn-active {
  border-color: #7deabf !important;
  background-color: #7deabf !important;
  outline: 0 !important;
}
.tag {
  margin-left: 10px;
}
.tag:first-child {
  margin-left: 0;
}
.el-row {
  width: 100%;
}
.plan-body {
  margin-bottom: 20px;
}

.plan-title {
  background-color: #02e3f4;
  padding: 5px 10px;
  margin-bottom: 15px;
  font-size: 14px;
}
.el-icon-lx-home:before {
  font-size: 60px;
}

.project-container {
  border: 1px solid #a7a7a7;
  box-shadow: 0 2px 5px #a7a7a7;
  padding: 10px;
  margin: 10px;
}

.project-title {
  padding: 0 10px;
}
.project-left {
  display: flex;
  align-items: center;
  justify-content: center;
}
.project-right {
  display: flex;
  flex-direction: column;
  justify-items: center;
  align-content: center;
}
.project-name {
  display: flex;
  align-items: center;
  justify-content: left;
  font-size: 14px;
  height: 60px;
  line-height: 20px;
  overflow: hidden;
  word-break: break-word;
  white-space: normal;
  margin-bottom: 10px;
}
.project-bottom {
  line-height: 20px;
}
.project-link {
  border: 1px solid #a7a7a7;
  padding: 0 10px;
  border-radius: 8px;
  font-size: 14px;
}
</style>